html,
body {
  border: 0;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15pt;
}

code {
  font-family: 'Ubuntu Mono', source-code-pro, Menlo, Monaco, Consolas,
    'Courier New', monospace;
  white-space: pre-wrap;
}

textarea {
  border: 0;
  font-family: 'Ubuntu Mono';
  font-size: 1em;
  margin: 0;
  outline: none;
  resize: none;
}
