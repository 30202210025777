.wrapper {
  display: flex;
}

.raw {
  background: black;
  color: skyblue;
  flex: 1;
  overflow-y: scroll;
  padding: 1em;
}

.rendered {
  background: #101010;
  color: lightgray;
  flex: 2;
  overflow-y: scroll;
  padding: 1em;
}
